import React from 'react';
import LogoLockup from '../../../images/logo_lockup.svg';
import { Container, Subtitle, LinksCont } from './styles';
import { Link } from 'gatsby';
function Header() {
  const pathname = typeof window !== 'undefined' ? window.location.pathname : ''; 
  return (<Container>
    <span>
      <LogoLockup />
      <Subtitle>Ready to live in apartments, housekept by robotic assistants.</Subtitle>
    </span>
    <LinksCont>
      <Link to="/" style={{ paddingRight: '14px', textDecoration: `${pathname === '/' || pathname === '' ? 'underline' : 'none'}`}}>Customer</Link>
      <Link to="/team" style={{ paddingRight: '14px', textDecoration: `${pathname === '/team' ? 'underline' : 'none'}` }}>Investor</Link>
    </LinksCont>
  </Container>
  )
}

export default Header;
