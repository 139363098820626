import React from 'react';
import { Container } from './styles'
function Footer() {
    return (
        <Container>
            <p><strong>Hi there!</strong> Thanks for visiting our site 🤗 -- We’re still in our early stages, but would love to hear from you. Whether you’re an engineer, landlord, reit, or Investor or would just like to chat.</p>
            <span>
                <p><strong>Contact Us</strong></p>
                <p> 📞 412-996-1311</p>
            </span>
        </Container>
    )
}

export default Footer