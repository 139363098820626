/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// import Img from "gatsby-image";
import BackgroundImage from 'gatsby-background-image'
import Chevron from '../../../images/chevron.svg';
import * as TWEEN from '@tweenjs/tween.js';

function TitleImage(props) {
    const { imageSrc } = props;
    const chevRef = useRef();
    const bounceAnimate = useRef();
    const scrolled = useRef(false);
    // console.log(imageSrc);
    useEffect(() => {
        if (!chevRef.current) return;
        if (typeof window === undefined) return;
        
        const listener = window.addEventListener('scroll', () => {
            if (scrolled.current) return;
            const opac = { val: 0.3 }
            const fadeoutTween = new TWEEN.Tween(opac).to({ val: 0 }, 250)
                .onUpdate(() => {
                    if (!chevRef.current) return;
                    chevRef.current.style.opacity = opac.val;
                }).onComplete(() => {
                    cancelAnimationFrame(bounceAnimate.current);

                }).start();
            scrolled.current = true;
            // window.removeEventListener('scroll', listener);
        })
    }, [chevRef])
    useEffect(() => {
        const animate = (time) => {
            bounceAnimate.current = requestAnimationFrame(animate);
            TWEEN.update(time);
        }
        bounceAnimate.current = requestAnimationFrame(animate);
        return () => {
            cancelAnimationFrame(bounceAnimate.current);
        }
    })
    useEffect(() => {
        if (!chevRef.current) return;
        const coord = { y: 0 }
        setTimeout(() => {
            if (scrolled.current) return;

            
            const downTween = new TWEEN.Tween(coord).to({ y: 0 }, 250)
                .onUpdate(() => {
                    if (!chevRef.current) return;
                    chevRef.current.style.bottom = `${35 + coord.y}px`
                })
                .easing(TWEEN.Easing.Quadratic.In)
                .onComplete(() => {
                    upTween.start();

                })
            const upTween = new TWEEN.Tween(coord).to({ y: 10 }, 250)
                .onUpdate(() => {
                    if (!chevRef.current) return;
                    chevRef.current.style.bottom = `${35 + coord.y}px`
                })
                .easing(TWEEN.Easing.Quadratic.Out)
                .delay(2000)
                .onComplete(() => {
                    downTween.start();
                }).start();
        }, 5000);
        return () => {
            TWEEN.removeAll(coord);
        }
    }, [chevRef]);
    return (
        <>
            <BackgroundImage
                fluid={imageSrc}
                imgStyle={{ objectFit: 'contain' }}
                style={{ height: 'calc(100% - 123px)', border: '2px solid #000000' }}
            >
            </BackgroundImage>
            <span ref={(ref) => chevRef.current = ref} style={{ position: 'absolute', zIndex: '999', left: '50%', bottom: '35px', transform: 'translateX(-50%)', opacity: '30%' }} >
                <Chevron />
            </span>
        </>
    )
}
TitleImage.propTypes = {
    imageSrc: PropTypes.any,
    alt: PropTypes.string
}

export default TitleImage;