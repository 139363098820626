import styled from 'styled-components'

export const Container = styled.div`
    background-color: #000000;
    width: 100%;
    margin-left: -24px;
    margin-top: 15px;
    padding: 27px 24px 27px 24px;
    max-height: 142px;
    display: flex;
    justify-content: space-between;
    scroll-snap-align: end;

    p {
        font-family: Open Sans;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 116%;
max-width: 537px;
margin: 0px;
/* or 21px */


/* Gray 6 */

color: #F2F2F2;
    }

    span {
        display: flex;
        flex-direction: column;

        p:not(:first-child)  {
            padding-top: 8px;
        }
    }
    @media screen and (max-width: 800px) {
            flex-direction: column;
            max-height: 500px;
            p{
                margin-bottom: 30px;
            }

      }            
          

`