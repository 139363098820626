/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Container, TextStyles } from './styles';
import Marquee from "react-fast-marquee";

const Warning = () => {
    const [showWarning, setShowWarning] = useState(true);
    const warning = '-- 🚨[WORK IN PROGRESS]🚨 -- THIS WEBSITE IS SPECULATIVE AND FOR INVESTORS OR POTENTIAL PARTNERS -- 🤔 --INTERESTED ? -- 🤔 -- WANT TO KNOW MORE? FEEL FREE TO DROP US A LINE @ 412-996-1311 --  🚨[CONFIDENTIAL]🚨';
    // useEffect(() => {
    //     let tempWarning = warning
    //     const to = setInterval(() => {
    //         tempWarning = tempWarning.slice(-1) + tempWarning.slice(0, -1);
    //         // console.log(tempWarning)
    //         setWarning(tempWarning);
    //     }, 200)
    //     return () => {
    //         clearInterval(to);
    //     }
    // }, [setWarning])
    useEffect(() => {
        if (typeof window === undefined) return;
        if (window.innerWidth > 1000 && !showWarning) {
            setShowWarning(true);
        } 
        if (window.innerWidth < 1000 & showWarning) {
            setShowWarning(false);
        }
        const listener = window.addEventListener('resize', () => {
            if (window.innerWidth > 1000 && !showWarning) {
                setShowWarning(true);
            }
            if (window.innerWidth < 1000 & showWarning) {
                setShowWarning(false);
            }
        })

        return () => {
            window.removeEventListener('resize', listener);
        }
    }, [showWarning]);
    return (
        // <Container>
        <>
            { showWarning && <Marquee style={{ height: '20px', background: 'black', left: '10px', top: '-10px', position: 'fixed', width: '100vh', transformOrigin: 'left', marginRight: '20px', transform: 'rotate(90deg)', zIndex: 10 }}
                speed={0.25}
                gradient={false}
                direction="right"
            >
                <TextStyles >{warning}</TextStyles>
            </Marquee>}
        </>

    )

}

export default Warning;