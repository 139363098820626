import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    padding-top: 19px;
    padding-bottom: 18px;
    justify-content: space-between;
    svg { 
        width: 97px;
        flex-shrink: 0;
    }

    p, a{
        font-family: Open Sans;
font-style: normal;
font-weight: normal;
font-size: 17px;
margin: 0px;
color: rgba(0, 0, 0, 0.7);
    }
    // @media screen and (max-width: 500px) {
    //     p {
    //         font-size: 14px;
    //     }
  }            
      

    span {
        display: flex;
    align-items: flex-end;
    }
`

export const Subtitle = styled.p`
    padding: 0px 0px 1px 37px;
    max-width: 260px;
    font-family: Open Sans;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 22px;

`

export const LinksCont = styled.span`
    height: 100%;
    @media screen and (max-width: 600px) {
        display: none !important;
        visibility: hidden;

    }
`