import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Arrow from '../../../images/arrow.svg';
import { Container, Header } from './styles';
const DropDownable = (props) => {
    const { defaultState, title } = props;
    const [isOpen, setIsOpen] = useState(defaultState);
    return (
    <Container>
        <Header onClick={() => setIsOpen(!isOpen)}>
            <h1>{title}</h1>
            <span style={{ transform: `${isOpen ? 'rotateZ(0deg)' : 'rotateZ(180deg)'}`}}>
                <Arrow/>
            </span>
            
         </Header>
        {isOpen && props.children}
    </Container>)
}

DropDownable.propTypes = {
    defaultState: PropTypes.bool,
    children: PropTypes.node,
    title: PropTypes.string
}

export default DropDownable;