import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 11px;
    scroll-snap-align: start;
`

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 5px solid #000000;
    h1 {
        margin: 0px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        color: #000000;
    }
    svg {
        padding-top: 4px;
    }
    span {
        transition: 0.5s ease;
    }
`