import React from 'react'
import PropTypes from 'prop-types'
import { Container, Header, Body } from './styles'
function TextParagraph (props) {
    const { header, body } = props;
    return (
        <Container>
            <Header>{header}</Header>
            <Body>{body}</Body>
        </Container>
    )
}

TextParagraph.propTypes = {
    header: PropTypes.string,
    body: PropTypes.string
}

export default TextParagraph;