import styled from 'styled-components';


export const Container = styled.div`
    width: 20px;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    background: black;
`

export const TextStyles = styled.p`
font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: white;
    margin-top: 0px;
    width: max-content;
    text-wrap: no-wrap;
    height: 20px;
`
