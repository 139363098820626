import styled from 'styled-components'

export const Container = styled.div`
max-width: 429px;
display: flex;
flex-direction: column;
p {
    margin: 0px;
}

`

export const Header = styled.p`
font-family: Poppins, sans-serif;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 36px;

/* Blue 1 */

color: #000000;
`

export const Body = styled.p`
font-family: Open Sans;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 25px;

color: rgba(0, 0, 0, 0.7);

`